import { BasilUserSolid } from '@/assets/icons/guide/BasilUserSolid';
import BaseTopNav from '@/components/base/baseTopNav';
import useStateHooks from '@/pages/invite/index/useStateHooks';
import { useModel } from '@umijs/max';
import QRCode from 'qrcode.react';
import { useTranslation } from 'react-i18next';

export default () => {
  const { t } = useTranslation();
  const { currentInvitationCodeData,invitationCodeQR } = useStateHooks();
  const { appInfo } = useModel('appInfo');
  const { user } = useModel('user');
  const { isPc } = useModel('system');

  return (
    <div className="w-full">
      <BaseTopNav title={t('二维码')} />
      <div className="pt-4 bg-[#191919] px-4 h-[100vh] ">
        <div className="w-full text-start flex items-center">
          {appInfo?.APP_BASE_LOGO ? (
            <img
              src={appInfo?.APP_BASE_LOGO}
              className="w-[28px] cursor-pointer  box-content "
              alt="logo"
            />
          ) : (
            <BasilUserSolid className="ml-3 mr-3 text-2xl text-titleColor" />
          )}
          <span className="text-[16px] leading-[17px] font-bold text-[#fff] ml-[6px]">
            {appInfo?.APP_BASE_NAME}
          </span>
        </div>
        {/*  */}
        <div
          className="mt-[80px] !min-h-[463px] relative bg-primary rounded-[20px]"
          // style={{
          //   backgroundImage: `url(${require('@/assets/img/invite/QR_Code.png')})`,
          //   backgroundRepeat: 'no-repeat',
          //   backgroundClip: 'content-box',
          //   backgroundSize: '100% 100%',
          // }}
        >
          {/* 头像 */}
          {/* <div className="w-full flex justify-center">
            <div className="absolute -mt-[35px] w-[63px] h-[63px] rounded-full border-[5px] border-solid border-[#fff]">
              {user?.avatar ? (
                <Image
                  className="w-14 h-14 flex-shrink-0 rounded-full object-cover"
                  src={user?.avatar}
                />
              ) : (
                <Image
                  className="w-14 h-14 flex-shrink-0 rounded-full object-cover !border-[#fff]"
                  src={require('@/assets/img/defaultAvatar.png')}
                />
              )}
            </div>
          </div> */}
          {/* 昵称 */}
          {/* <div className="pt-[40px] w-full text-center text-[18px] leading-[18px] font-medium text-[#000]">
            {user?.name}
          </div> */}
          <div className="relative pt-[42px]">
            <div
              className={`w-full text-center text-[14px] leading-[22px] font-semibold text-[#fff]`}
            >
              {t('快与我一起加入 {{data}}', { data: appInfo?.APP_BASE_NAME })}
            </div>
            <div className="mt-3 w-full text-center">
              <span className="text-[14px] py-[3px] px-[30px] bg-[#98C3FF] text-[#0052C7] rounded-[20px]">
                {t('畅享返佣乐趣')}
              </span>
            </div>
            {/* 二维码${isPc?'mt-[3%]':'mt-[8%]'} */}
            <div
              className={`w-full flex flex-wrap justify-center mt-[14px] mb-2 mt-[36px]`}
            >
              <div className="min-w-[146px] min-h-[146px] bg-[#fff] border-[1px] border-solid border-[#0000000D] p-[15px]">
                <QRCode
                  value={invitationCodeQR??currentInvitationCodeData?.popularizeUrl}
                  size={123} // 二维码的大小
                />
              </div>
              {/* <div className="mt-2 w-full text-center text-[12px] leading-[16px] text-auxiliaryTextColor">
              {t('长按识别二维码下载')}
            </div> */}
            </div>
          </div>
          <div>
            <img
              src={require('@/assets/img/invite/QR_Code_bottom.png')}
              className="w-full rounded-b-[20px]"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};
